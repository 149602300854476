import {
  Login,
  Register,
  ForgotPassword,
  SetPassword,
  Home,
  Applications,
  Reports,
  Search,
  Profile,
  DisbursementReports,
  Administrator,
  LeadsWelcome,
  LeadsContent,
  Risk,
  PartnerProfile,
  Metrics,
  Integrations,
  Digest,
  Admissions,
  Permissions,
  Lite,
} from 'constants/copy/pages'
import { APP_NAME } from 'constants/countryInfo'
import { REACT_APP_MAXIMUM_PARTNER_USER } from 'env'

export const LOGIN_COPY: CopyRecord<Login> = {
  [Login.heading]: 'Log in to your partner dashboard',
  [Login.subheading]:
    'Please input your registered email address and password.',
  [Login.alert]: `Your session has timed out. We have logged you out for your security. To access your dashboard, please log in again.`,
}

export const REGISTER_COPY: CopyRecord<Register> = {
  [Register.heading]: `Finish your registration at ${APP_NAME}`,
  [Register.subheading]: 'Secure your account by creating a password.',
  [Register.agreement]: `I have read and agree to the <0>{{termsAndConditions}}</0> and <1>{{privacyPolicy}}</1> of ${APP_NAME}.`,
  [Register.termsAndConditions]: 'Terms and Conditions',
  [Register.privacyPolicy]: 'Privacy Policy',
}

export const FORGOT_PASSWORD_COPY: CopyRecord<ForgotPassword> = {
  [ForgotPassword.heading]: 'Forgot your password?',
  [ForgotPassword.subheading]:
    'Please input your registered email address to continue.',
  [ForgotPassword.success]: 'Successfully sent password reset email.',
}

export const SET_PASSWORD_COPY: CopyRecord<SetPassword> = {
  [SetPassword.heading]: 'Set your password',
  [SetPassword.subheading]: 'Please set your new password',
}

export const HOME_COPY: CopyRecord<Home> = {
  [Home.title]: 'My Dashboard',
  [Home.widgetTitleDisbursement]: 'Total Disbursements',
  [Home.quickLink]: `Quick Links`,
  [Home.quickLinkSubtitle]: `Shortcut to our most useful links`,
  [Home.quickLinkBlankTitle]: `No quick links available at the moment`,
  [Home.quickLinkBlankSubtitle]: `Make sure to check back with us next time.`,
  [Home.widgetTitleStudent]: 'Students Financed',
  [Home.widgetInfo]:
    'Total amount disbursed to your school to enable tuition financing.',
  [Home.widgetInfoStudent]:
    'Unique students with activated installment plan/s.',
  [Home.greeting]: `Hello`,
  [Home.news]: `Watch this space for update on new features and your most recent activations.`,
  [Home.welcome]: `Welcome to your ${APP_NAME} dashboard!`,
  [Home.chooseLanguage]: `Choose Language :`,
  [Home.applications]: 'New Activations',
  [Home.applicationsSubheading]: 'You have <0>{{count}}</0> new activation/s',
  [Home.reports]: 'Latest Disbursements',
  [Home.reportsSubheading]: 'Summary of latest disbursements',

  [Home.onboardingHeading]: 'Welcome to your partner dashboard!',
  [Home.onboardingDescription]: `This dashboard is a helpful tool for tracking applications, sending notifcations, accessing reports, generating leads, and completing your profile.`,
  [Home.onboardingTrack]: 'Track applications and send notifications',
  [Home.onboardingTrackDescription]: `See applications move down the funnel in real-time. Send notifications to activated applicants to let them know you have received the confirmation of their installment plan.`,
  [Home.onboardingDownloadReports]: 'Download reports',
  [Home.onboardingDownloadReportsDescription]:
    'Download disbursement reports right from your dashboard. You may also send notifications in bulk to all recently activated applicants.',
  [Home.onboardingLeads]: 'Generate leads',
  [Home.onboardingLeadsDescription]:
    'Bump up your enrollment rates by referring students to apply for an installment plan with Bukas.',

  [Home.partnershipSurveyHeading]: 'Partnership Survey',
  [Home.partnershipSurveyDescription]:
    'Hello! We just wanted to check up on you and get feedback on what you think about our partnership and your dashboard. Please take 5 minutes to answer our survey.',

  [Home.restrictedAccessTitle]: 'Restricted Access',
  [Home.restrictedAccessSubtitle]:
    'Please coordinate with your account manager to change your permissions.',

  [Home.emptyActivation]: 'Oops, no new activations for today.',
  [Home.emptyActivationButtonText]: 'Add Leads',
  [Home.emptyActivationSubtitle]: `Make sure to check back with us tomorrow.`,
  [Home.emptyActivationAddLeads]: `In the meantime, you may add leads to increase your enrollment rates.`,
}

export const APPLICATIONS_COPY: CopyRecord<Applications> = {
  [Applications.title]: 'Loans',
  [Applications.tabAll]: 'All Applications',
  [Applications.tabReportGenerated]: 'Report Generated',
  [Applications.tabReportReceived]: 'Report Received',
  [Applications.revisionOverviewBadge]: 'PENDING STUDENT RESOLUTION',
  [Applications.revisionOverviewTitle]: 'Incomplete Information/Documents',
  [Applications.revisionOverviewSubtitle]:
    'The student needs to revise the following:',

  [Applications.headingName]: 'Name',
  [Applications.headingMobileNumber]: 'Mobile Number',
  [Applications.headingEmail]: 'Email',
  [Applications.headingSchool]: 'School',
  [Applications.headingIdNum]: 'Student ID',
  [Applications.headingDegree]: 'Degree',
  [Applications.headingProgram]: 'Program',
  [Applications.headingDateSubmitted]: 'Date of Submission',
  [Applications.headingDateActivated]: 'Date of Activation',
  [Applications.headingCoborrower]: 'Co-Borrower',
  [Applications.headingDisbursedAmount]: 'Disbursed Amount',
  [Applications.headingRefundAmount]: 'Refund Amount',
  [Applications.headingApprovedAmount]: 'Approved Amount',
  [Applications.headingRequestedAmount]: 'Requested Amount',
  [Applications.headingAmount]: 'Amount',
  [Applications.headingReference]: 'Reference #',
  [Applications.headingLoanStatus]: 'Loan Status',
  [Applications.headingLoanProduct]: 'Loan Product',
  [Applications.headingReportStatus]: 'Disbursement Status',
  [Applications.headingAction]: 'Action',
  [Applications.filterDateActivated]: 'Date Activated',
  [Applications.filterCourse]: 'Course',
  [Applications.filterLoanStatus]: 'Loan Status',
  [Applications.filterPartnerName]: 'School',

  [Applications.pendingReportStatus]: '-',
  [Applications.newReportStatus]: 'New',
  [Applications.postedReportStatus]: 'Posted',

  [Applications.degreeAll]: 'Showing all degrees',

  [Applications.degreeBS]: 'Bachelor of Science (BS)',
  [Applications.degreeBA]: 'Bachelor of Arts (BA)',
  [Applications.degreeB]: 'Bachelor (B)',
  [Applications.degreeBFA]: 'Bachelor of Fine Arts (BFA)',
  [Applications.degreeMS]: 'Master of Science (MS)',
  [Applications.degreeMA]: 'Master of Arts (MA)',
  [Applications.degreeM]: 'Master (M)',
  [Applications.degreeD]: 'Doctor (D)',
  [Applications.degreePhD]: 'Doctor of Philosophy (PhD)',
  [Applications.degreeMD]: 'Doctor of Medicine (MD)',
  [Applications.degreeJD]: 'Juris Doctor (JD)',
  [Applications.degreeShort]: 'Short Course / Training Program',

  [Applications.allDays]: 'All',
  [Applications.day]: 'Day',
  [Applications.days]: 'Days',
  [Applications.applyFilter]: 'Apply Filters',
  [Applications.filterBy]: 'Filter By',
  [Applications.sendNotification]: 'Send Notification',
  [Applications.reset]: 'Reset',

  [Applications.degreeD1]: '',
  [Applications.degreeD2]: '',
  [Applications.degreeD3]: '',
  [Applications.degreeS1]: '',
  [Applications.degreeS2]: '',
  [Applications.degreeS3]: '',

  [Applications.postSuccess]: 'Notification sent successfully!',
  [Applications.postSuccessMessage]:
    'We have sent an email to the student/s to inform them that their installment plan will reflect in their school account soon.',
  [Applications.postErrorMessage]: 'Failed. Try again',
  [Applications.overviewTitle]: 'Applicant Overview',
  [Applications.labelPayment]: 'Monthly payment',
  [Applications.labelServiceFee]: 'One-time service fee',
  [Applications.labelRequestedAmount]: 'Total requested amount',
  [Applications.labelLoanDuration]: 'Loan duration',
  [Applications.labelInterest]: 'Interest applied per month',
  [Applications.labelTotalAmount]: 'Total amount including interest',
  [Applications.tenor]: '{{tenor}} months',

  [Applications.VAUpdateTitle]: 'Virtual Account Update/s',
  [Applications.needUpdating]:
    'You have {{number}} account/s that need updating',
  [Applications.studentID]: 'ID: {{id}}',
  [Applications.approvedPrincipal]: 'Amount: {{amount}}',
  [Applications.bankSelectionSectionTitle]: 'Bank Name',
  [Applications.inputVASectionTitle]: 'Student Virtual Account',
  [Applications.VAHelper]: 'VA must be numbers only',
  [Applications.modalsubmitTitle]: 'Update Virtual Account',
  [Applications.modalsubmitInfo]:
    'Kindly check that the virtual account number you entered is correct.',
  [Applications.bankNamePlaceholder]: 'Bank name',
  [Applications.VAPlaceholder]: 'VA number',

  [Applications.customView]: 'Custom View',
  [Applications.customViewPopoverSubheading]:
    'With Custom View, you can quickly and easily access the specific loans you want to see on your dashboard.',
  [Applications.customViewEmpty]: 'No custom view added',
  [Applications.customViewCreate]: 'Add now',
  [Applications.customViewSelect]: 'Select a Custom View',
  [Applications.customViewSelectPlaceholder]: 'Select one',
  [Applications.customViewModalMainHeading]: 'What is a Custom View?',
  [Applications.customViewModalMainSubheading]:
    'Custom View helps you organize your filtered preferences, so you can easily find the information you need on your dashboard.',
  [Applications.customViewTitleInputLabel]: 'Custom View Name',
  [Applications.customViewTitleInputPlaceholder]:
    'Your custom view name, e.g., Application Monthly Report',
  [Applications.customViewAccessInputLabel]: 'Who Can Access the Custom View?',
  [Applications.customViewAccessOptionTeam]: 'Entire Team',
  [Applications.customViewAccessOptionSelf]: 'Only Me',
  [Applications.customViewActionCancel]: 'Cancel',
  [Applications.customViewActionCreate]: 'Add Custom View',
  [Applications.customViewActionSaveAsNew]: 'Save as New Custom View',
  [Applications.customViewActionOverwrite]: 'Overwrite Changes',
  [Applications.customViewActionOverwriteConfirm]: 'Overwrite Confirmation',
  [Applications.customViewOverwriteConfirmDescription]:
    'By clicking ‘Overwrite Changes’ you will change the Custom View settings for',
  [Applications.customViewActionDelete]: 'Delete Custom View',
  [Applications.customViewActionAddNew]: 'Add to Custom View',
  [Applications.customViewConfirmChange]: 'Confirm change?',
}

export const REPORTS_COPY: CopyRecord<Reports> = {
  [Reports.title]: 'Reports',
  [Reports.subheading]:
    'Keep track of your school’s performance with real-time updates of activated accounts.',
  [Reports.reportSectionTitle]: 'Summary',
  [Reports.filterDegree]: 'By Degree',
  [Reports.filterYear]: 'By Year Level',
  [Reports.degree]: 'Degree',
  [Reports.year]: 'Year Level',
  [Reports.byNumber]: 'Activations by Number',
  [Reports.byAmount]: 'Activations by Amount',
}

export const DISBURESMENT_REPORTS_COPY: CopyRecord<DisbursementReports> = {
  [DisbursementReports.title]: 'Disbursement Reports',
  [DisbursementReports.subheading]:
    'Keep track of your school’s performance with real-time updates of activated accounts.',
  [DisbursementReports.partnerName]: 'School',
  [DisbursementReports.bankReferenceCode]: 'Bank Reference',
  [DisbursementReports.dateDisbursement]: 'Date of Disbursement',
  [DisbursementReports.numberOfActivation]: 'Number of Activations',
  [DisbursementReports.disbursedAmount]: 'Disbursed Amount',
  [DisbursementReports.totalRefundAmount]: 'Refunded Amount',
  [DisbursementReports.actualFundAmountDisbursed]:
    'Actual Fund Amount Disbursed',
  [DisbursementReports.modalTitle]: 'Attention',
  [DisbursementReports.modalAlertSafari]: `You are using Safari. To download the file to your device, click <0>command + shift + s</0> and Select <1>Page Source</1> as the file format.`,
  [DisbursementReports.modalContent]: `Downloading this disbursement report will automatically change the report status of these loans to <0>“Posted”</0> and will trigger an email notifying these students that their installment plans will reflect in their school account soon.`,
  [DisbursementReports.modalContentDowloaded]: `You are about to download this report to your device. `,
  [DisbursementReports.modalAction]: 'Download Report',
  [DisbursementReports.modalActionWithSendNotif]:
    'Download Report & Send Notification',
  [DisbursementReports.postSuccess]: 'Report received!',
  [DisbursementReports.postSuccessMessage]:
    'We have sent an email to the student/s to inform them that their installment plan will reflect in their school account soon.',
}

export const SEARCH_COPY: CopyRecord<Search> = {
  [Search.title]: 'Search Results',
  [Search.subheading]: 'Showing search results for “{{query}}”',
}

export const PROFILE_COPY: CopyRecord<Profile> = {
  [Profile.title]: 'Profile',

  [Profile.email]: 'Add Contact Person',
  [Profile.emailPlaceholder]: 'Please input a new partner email address.',
  [Profile.labelPlaceholder]: 'Label (e.g. “Primary”)',

  [Profile.contact]: 'Add Contact Number',
  [Profile.contactDescription]: 'Please input a new partner contact number.',
  [Profile.typePlaceholder]: 'Select type...',
  [Profile.typePhone]: 'Phone',
  [Profile.typeMobile]: 'Mobile',

  [Profile.editBasicInfo]: 'Edit Basic Information',
  [Profile.partnerLogo]: 'Partner Logo',
  [Profile.basicInfo]: 'Basic Information',
  [Profile.addressInfo]: 'Address Information',
  [Profile.provincePlaceholder]: 'Select a province...',
  [Profile.cityPlaceholder]: 'Select a city or town...',

  [Profile.noAddress]: 'No address provided',
  [Profile.noEmail]: 'No email address provided',
  [Profile.noContact]: 'No contact number provided',

  [Profile.overview]: 'Overview',
  [Profile.contactInfo]: 'Contact Information',
  [Profile.partnerProfile]: 'Partner’s Profile',
  [Profile.labelSchool]: 'School / University',
  [Profile.labelAddress]: 'Address',
  [Profile.deletePhoneTitle]:
    'Are you sure you want to remove your contact number?',
  [Profile.deletePhoneMessage]:
    'You’re about to remove {{phone}} ({{label}}) from your account.',
  [Profile.deleteEmailTitle]:
    'Are you sure you want to remove your email address?',
  [Profile.deleteEmailMessage]:
    'You’re about to remove {{email}} ({{label}}) from your account.',
  [Profile.headingEmail]: 'Email Address',
  [Profile.headingPhone]: 'Phone or Mobile Number',
}

export const ADMINISTRATOR_COPY: CopyRecord<Administrator> = {
  [Administrator.titleStep1]: 'Set up your roster',
  [Administrator.descStep1]: 'Add up to 5 users to your partner dashboard. ',
  [Administrator.titleStep2]: 'Specify their access',
  [Administrator.descStep2]:
    'Dictate what each user can and cannot access on your partner dashboard.',
  [Administrator.titleStep3]: 'Assign to multiple schools',
  [Administrator.descStep3]:
    'Specify which schools each admin user has access to. ',

  [Administrator.titleBlankState]: 'Register your admin now!',
  [Administrator.descBlankState]:
    'You can add up to 5 admin users per campus. ',
  [Administrator.actionBlankState]: 'Add User',

  [Administrator.titleModalAddAdmin]: 'Add new admin user',
  [Administrator.descModalAddAdmin]:
    'You are about to add a new admin user to your partner dashboard. Click “Submit” to proceed.',

  [Administrator.addAdminBtn]: 'Add new admin',
  [Administrator.alertMaxUser]:
    'Sorry, you have reached the maximum number of partner users.',
  [Administrator.addUserSuccessTitle]: 'Added new admin successfully!',
  [Administrator.addUserSuccessDesc]: `We have sent an email invitation to the new admin/s with instructions on how to finish setting up their account.`,

  [Administrator.removeFormAdmin]: `Clear this form`,
}

export const LEADS_WELCOME_COPY: CopyRecord<LeadsWelcome> = {
  [LeadsWelcome.title]: 'Welcome to Leads Management!',
  [LeadsWelcome.firstOptionTitle]: 'Get more students',
  [LeadsWelcome.firstOptionContent]: `Refer your students to ${APP_NAME} and bump up your enrollment and retention rates.`,
  [LeadsWelcome.secondOptionTitle]: `Notify them about ${APP_NAME}`,
  [LeadsWelcome.secondOptionContent]: `Inform your students that they have the option to apply with ${APP_NAME} by sending them an SMS.`,
  [LeadsWelcome.thirdOptionTitle]: 'Help your students',
  [LeadsWelcome.thirdOptionContent]: `Make tuition fee payments more affordable by referring your students to ${APP_NAME}.`,
  [LeadsWelcome.registerTitle]: 'Register your students now!',
  [LeadsWelcome.registerContent]:
    'Add new leads, monitor their progress, and track conversion from your leads management system.',
}

export const LEADS_CONTENT_COPY: CopyRecord<LeadsContent> = {
  [LeadsContent.title]: 'Leads',

  [LeadsContent.addLeadTitle]: 'Add a New Lead',
  [LeadsContent.addLeadSubTitle]:
    'Encode the student’s basic information and let them know they have the option to apply with Bukas.',
  [LeadsContent.addLeadContent]: ``,

  [LeadsContent.dateInfo]: 'as of {{date}}',
  [LeadsContent.leadInfoTitle]: 'Leads',
  [LeadsContent.totalLead]: 'Total Leads',
  [LeadsContent.thisMonthLead]: 'Past 30 Days',
  [LeadsContent.lastWeekLead]: 'Past 7 Days',

  [LeadsContent.convertionRateTitle]: 'Conversion Rate',
  [LeadsContent.convertionRateRegistration]: 'Registrations',
  [LeadsContent.convertionRateApplication]: 'Applications',
  [LeadsContent.convertionRateActivation]: 'Activations',

  [LeadsContent.leadTypeFilter]: 'Lead Type',
  [LeadsContent.schoolFilter]: 'Select School',
  [LeadsContent.loanStatusFilter]: 'Select Loan Status',
  [LeadsContent.levelFilter]: 'Progress',

  [LeadsContent.leadTypeFilterPlacholder]: 'Select Lead',
  [LeadsContent.schoolFilterPlaceholder]: 'Select School',
  [LeadsContent.loanStatusFilterPlaceholder]: 'Select Loan Status',
  [LeadsContent.levelFilterPlaceholder]: 'Select Progress',

  [LeadsContent.tabAllLeads]: 'Leads',
  [LeadsContent.tabDraft]: 'Drafts',
  [LeadsContent.tabErrors]: 'Errors',

  [LeadsContent.headingResendSMS]: 'Notify Lead',
  [LeadsContent.headingType]: 'Type',
  [LeadsContent.headingName]: 'Name (Last, First)',
  [LeadsContent.headingEmail]: 'Email',
  [LeadsContent.headingUsername]: 'Mobile Number',
  [LeadsContent.headingSchool]: 'School',
  [LeadsContent.headingLoanProduct]: 'Loan Product',
  [LeadsContent.headingDateAdded]: 'Date Added',
  [LeadsContent.headingAddedByName]: 'Added By',
  [LeadsContent.headingLeadStatus]: 'Status',
  [LeadsContent.headingLeadProgress]: 'Progress',
  [LeadsContent.headingAction]: 'Action',
  [LeadsContent.headingDatetimeCreated]: 'Data Uploaded',
  [LeadsContent.headingUploadedBy]: 'Uploaded By',
  [LeadsContent.headingErrorFile]: 'Error File',
  [LeadsContent.headingIdNumber]: 'Student ID',

  [LeadsContent.resendSMSHoverInfo]: 'Send follow-up SMS & E-mail',
  [LeadsContent.unableToResendSMSHoverInfo]:
    'You can only send one follow-up SMS & E-mail per user',

  [LeadsContent.resendSMSSuccessTitle]: 'Follow-up SMS & E-mail sent!',
  [LeadsContent.resendSMSSuccessDesc]:
    'You have successfully sent a follow-up SMS & E-mail.',
  [LeadsContent.resendSMSErrorTitle]: 'Sending failed',
  [LeadsContent.resendSMSErrorDesc]:
    'Send follow-up SMS & E-mail failed, please try again.',

  [LeadsContent.filterBy]: 'Filter By',

  [LeadsContent.firstModalTitle]: 'Welcome To Leads Management',
  [LeadsContent.firstModalContent]: `Hello! 👋 We have developed an easy way for you to recommend ${APP_NAME} and bump up your enrollment and retention rates.`,
  [LeadsContent.secondModalTitle]: 'What is a lead?',
  [LeadsContent.secondModalContent]: `Leads are students you would like to recommend for a ${APP_NAME} installment plan. They can be new students looking for alternative payment options or students who need additional financing.`,
  [LeadsContent.thirdModalTitle]: 'How do I add a lead?',
  [LeadsContent.thirdModalContent]:
    'Provide their basic information to add a student as a lead. You also have the option to provide additional details, but this is not required.',
  [LeadsContent.fourthModalTitle]: 'How do I track my leads?',
  [LeadsContent.fourthModalContent]:
    'Track your leads easily by referring to the status column and progress columns.',
  [LeadsContent.fifthModalTitle]: 'How do I access my drafts?',
  [LeadsContent.fifthModalContent]:
    'Encode your leads and save them for later. Access all the drafts you have saved on the specified tab.',
  [LeadsContent.sixthModalTitle]: 'How do I send an SMS?',
  [LeadsContent.sixthModalContent]: `Submit a lead to trigger the SMS notification to the student/s. The SMS informs the student/s that they have been recommended for an installment plan with ${APP_NAME}.`,

  [LeadsContent.leadsPopoverTitle]: 'Leads',
  [LeadsContent.leadsPopoverContent]:
    'Track how many leads you have submitted in total and compare it to how many you have added over the past month or week. ',
  [LeadsContent.convertionRatePopoverTitle]: 'Conversion Rate',
  [LeadsContent.convertionRatePopoverContent]:
    'Track your conversion rates by checking how many have registered for an account, how many have applied for an installment plan, and how many have activated contracts.',
  [LeadsContent.progressPopoverTitle]: 'Progress',
  [LeadsContent.progressPopoverContent]:
    'Easily track your leads using this progress bar. Each step completed translates to a highlighted bar in this column.',
  [LeadsContent.draftsPopoverTitle]: 'Drafts',
  [LeadsContent.draftsPopoverContent]:
    'Save your lead for later and come back to them by clicking on this tab.',
  [LeadsContent.submitPopoverTitle]: 'Submit Draft',
  [LeadsContent.submitPopoverContent]:
    'Submit your drafts as leads in bulk by using this button. You may also filter through your list before submitting them.',

  [LeadsContent.createLeadsModalTitle]: 'Add a new lead',
  [LeadsContent.StudentInfoLabel]: 'Student Information',
  [LeadsContent.firstNameFieldLabel]: 'First Name',
  [LeadsContent.firstNameFieldPlaceholder]: 'First Name',
  [LeadsContent.lastNameFieldLabel]: 'Last Name',
  [LeadsContent.lastNameFieldPlaceholder]: 'Last name',
  [LeadsContent.emailFieldLabel]: 'Email',
  [LeadsContent.mobileNumberFieldLabel]: 'Mobile Number',
  [LeadsContent.mobileNumberFieldPlaceholder]: '9xx xxxx xxxx',
  [LeadsContent.loanProductFieldLabel]: 'Loan Product',
  [LeadsContent.loanProductFieldPlaceholder]: 'Loan Product',
  [LeadsContent.dateOfBirthFieldLabel]: 'Date of Birth',
  [LeadsContent.dateOfBirthFieldPlaceholder]: 'YYYY-MM-DD',
  [LeadsContent.dateOfBirthFieldValidationError]:
    'The date must be in the format yyyy-mm-dd',
  [LeadsContent.schoolFieldLabel]: 'School',
  [LeadsContent.additionalInfoLabel1]: 'Additional Information (Optional)',
  [LeadsContent.additionalInfoLabel2]:
    '(Degree, Course/Program, Student ID, Student Address)',
  [LeadsContent.degreeFieldLabel]: 'Degree',
  [LeadsContent.degreeFieldPlaceholder]: 'Degree',
  [LeadsContent.courseFieldLabel]: 'Course/Program',
  [LeadsContent.courseFieldPlaceholder]: 'Course/Program',
  [LeadsContent.IDNumberFieldLabel]: 'Student ID Number',
  [LeadsContent.IDNumberFieldPlaceholder]: 'ID Number',
  [LeadsContent.addressFieldLabel]: 'Student Address',
  [LeadsContent.addressFieldPlaceholder]: 'Student Address',
  [LeadsContent.cityFieldLabel]: 'Student City',
  [LeadsContent.cityFieldPlaceholder]: 'Student City',
  [LeadsContent.provinceFieldLabel]: 'Student Province',
  [LeadsContent.provinceFieldPlaceholder]: 'Student Province',

  [LeadsContent.uploadBatchLeadsModalTitle]: 'Leads Batch Upload',
  [LeadsContent.uploadBatchLeadsModalHint]:
    'Make sure your file type is CSV/XLSX',
  [LeadsContent.uploadBatchLeadsModalUploadFileSection]: 'Browse File',
  [LeadsContent.uploadBatchLeadsModalUploadFileSectionPlacholder]:
    'Drag & drop your file here',
  [LeadsContent.uploadBatchLeadsTitleDescription]: 'Batch Upload',
  [LeadsContent.uploadBatchLeadsDescription]:
    'Please upload your leads file with the following columns: school, first_name, last_name, mobile_phone, email, course, loan_product, date_of_birth, create_user (y or n). ',

  [LeadsContent.downloadCSVSampleButton]: `<0>Download template</0>`,

  [LeadsContent.previewBatchLeadsModalTitle]: 'Data Summary',
  [LeadsContent.previewBatchLeadsModalUploadSuccess]: 'File uploaded',

  [LeadsContent.uploadStatusModalSuccessTitle]: 'Success',
  [LeadsContent.uploadStatusModalErrorTitle]: 'Error File',
  [LeadsContent.uploadStatusModalSuccessDescription]:
    'Your file has been uploaded and ingested',
  [LeadsContent.uploadStatusModalErrorDescription]: `Woops, looks like something
went wrong with your upload. Download the error file, make the necessary edits and try again. `,

  [LeadsContent.uploadProcessModalTitle]: `File is being processed`,
  [LeadsContent.uploadProcessModalDescription]: `Please check back later to see if your file was ingested successfully.`,

  [LeadsContent.sendSMSModalTitle]: 'Send SMS',
  [LeadsContent.sendSMSModalContent]: `You are about to send an SMS to this student informing them that they have the option to apply with ${APP_NAME}. Would you like to proceed?`,
  [LeadsContent.thankYouModalTitle]: 'Thank you',
  [LeadsContent.thankYouModalContent]:
    'We have sent an SMS to the student. You may track their progress from your leads dashboard.',

  [LeadsContent.aggregateTitle]: 'Leads Growth',
  [LeadsContent.aggregateTotal]: 'Total Leads',
  [LeadsContent.aggregateRegistration]: 'Bukas Account Registrations',
  [LeadsContent.aggregateApplication]: 'Application Submissions',
  [LeadsContent.aggregateActivated]: 'Students Activated & Enrolled',
  [LeadsContent.aggregateAmount]: 'Amount Disbursed From Leads',

  [LeadsContent.tooltipProgress]:
    'Quickly track your leads from registration, application and activation using this progress bar.',
  [LeadsContent.tooltipProgressNotYetRegister]: 'Not yet registered',
  [LeadsContent.tooltipProgressRegistered]: 'Registered (Not yet submitted)',
  [LeadsContent.tooltipProgressSubmitted]: 'Application submitted',
  [LeadsContent.tooltipProgressActivated]: 'Loan Activated',
}

export const RISK_COPY: CopyRecord<Risk> = {
  [Risk.title]: 'Blocking Access',

  [Risk.selectCohort]: 'Select Cohort',
  [Risk.selectCohortPlaceholder]: `{{month}} Cohort`,

  [Risk.summary]: 'Summary',
  [Risk.dateActivated]: 'Date activated',
  [Risk.dateActivatedPopover]:
    'Represents the date the first disbursement was made to the school.',
  [Risk.nextRepaymentDeadline]: 'Next repayment deadline',
  [Risk.nextRepaymentDeadlinePopover]:
    'Illustrates the next due date for the students in the cohort.',
  [Risk.finalDisbursement]: 'Final disbursement based on current performance',
  [Risk.finalDisbursementPopover]:
    'Projects the amount for your final disbursement based on how your cohort is currently performing.',
  [Risk.summaryCoachmarkingContent]:
    'Keep track of pertinent details and never skip a beat when it comes to monitoring your cohort.',

  [Risk.performance]: 'Performance',
  [Risk.activations]: 'Activations',
  [Risk.asOf]: 'As of',
  [Risk.npl]: 'Net Principal NPL',
  [Risk.netPrincipalNPL]: 'Net Principal NPL ',
  [Risk.approvedPrincipal]: 'Total Approved Principal for Cohort ',
  [Risk.nplStudentCount]: 'NPL student count ',
  [Risk.nonNplStudentCount]: 'Non NPL student count ',
  [Risk.performanceCoachmarkingContent]:
    'Monitor how your cohort is fairing month on month. We stop computing the NPL ratio a day after the cohort’s repayment deadline and update calculations daily for the current month.',

  [Risk.loans]: 'Loans',
  [Risk.headingRepaymentStatus]: 'Repayment Status',
  [Risk.headingName]: 'Name (Last, First)',
  [Risk.headingIdNum]: 'ID Number',
  [Risk.headingCourse]: 'Program',
  [Risk.headingReference]: 'Reference #',
  [Risk.headingLoanProduct]: 'Loan Product',
  [Risk.headingDateActivated]: 'Date of Activation',
  [Risk.loansCoachmarkingContent]:
    'Easily navigate through the list of students in your cohort and access their repayment status, name, ID number and more.',
  [Risk.headingInstitution]: 'Institution',
  [Risk.headingReportingDate]: 'Reporting Date',
  [Risk.headingTotalStudent]: 'Total Student',
  [Risk.headingDownloadReport]: 'Download Report',

  [Risk.MonthlyReport]: 'Preview & Disbursements',
  [Risk.StudentList]: 'Students',
}

export const PARTNER_PROFILE_COPY: CopyRecord<PartnerProfile> = {
  [PartnerProfile.title]: 'Profile',

  [PartnerProfile.joinedDate]: `Date Joined: {{date}}`,
  [PartnerProfile.population]: 'Population',
  [PartnerProfile.activations]: 'Total Activations',
  [PartnerProfile.program]: 'Programs',
  [PartnerProfile.completion]: `{{percentage}} Profile Completion`,

  [PartnerProfile.basicInformationSection]: 'Basic Information',
  [PartnerProfile.programSection]: 'Programs',
  [PartnerProfile.paymentsSection]: 'Payments',
  [PartnerProfile.imagesSection]: 'Images',

  [PartnerProfile.addressSectionTitle]: 'School Address',
  [PartnerProfile.addressSectionContent]:
    'Please make sure all information are updated.',

  [PartnerProfile.socialMediaAccountsSectionTitle]: 'Social Media Accounts',
  [PartnerProfile.socialMediaAccountsSectionContent]:
    'Please make sure all information are updated.',

  [PartnerProfile.contactNumberSectionTitle]: 'Contact Numbers',
  [PartnerProfile.contactNumberSectionContent]:
    'Please make sure all information are updated.',

  [PartnerProfile.contactNumberRemoveModalTitle]: 'Remove Contact Number',
  [PartnerProfile.contactNumberRemoveModalDescription]:
    'You are about to remove a contact number. Click "Remove" to proceed and "Cancel" to exit',

  [PartnerProfile.populationSectionTitle]: 'Population',
  [PartnerProfile.populationSectionContent]:
    'Please update your student population regularly. This will allow you to see how many borrowers are on Bukas versus the whole population.',
  [PartnerProfile.populationSectionDate]: 'Population as {{date}}',

  [PartnerProfile.contactPersonSectionTitle]: 'Directory',
  [PartnerProfile.contactPersonSectionContent]:
    'Please provide the email addresses and mobile numbers of pertinent contact persons. This will help us build a working directory and connect more meaningfully with concerned stakeholders.',

  [PartnerProfile.contactPersonRemoveModalTitle]: 'Remove Contact Person',
  [PartnerProfile.contactPersonRemoveModalDescription]:
    'You are about to remove a contact person. Click "Remove" to proceed and "Cancel" to exit',

  [PartnerProfile.programSectionTitle]: 'Programs',
  [PartnerProfile.programsSectionText]:
    'Please provide a list of your undergraduate/graduate programs and short courses. This will be used when students select which program they are part of while applying for an installment plan with us. This will also serve as additional information on the school partner page on our website.',
  [PartnerProfile.undergraduateProgramsSectionTitle]: 'Undergraduate Programs',
  [PartnerProfile.graduateProgramsSectionTitle]: 'Graduate Programs',
  [PartnerProfile.shortCoursesSectionTitle]: 'Short Courses',

  [PartnerProfile.registrationFeesSectionTitle]: 'Tuition Rates & Other Fees',
  [PartnerProfile.registrationFeesSectionContent]:
    'Please upload the latest copy of your tuitions rates & other fees. These fees will be posted on your school’s customized page on our website.',

  [PartnerProfile.paymentPoliciesSectionTitle]: 'Payment Policies',
  [PartnerProfile.paymentPoliciesSectionContent]:
    'Please answer our quick survey on payment policies. This will allow our operations team to process applications faster.',

  [PartnerProfile.internalFinancingProgramSectionTitle]:
    'Internal Financing Program/s',
  [PartnerProfile.internalFinancingProgramSectionContent]:
    'Please fill us in on your internal financing programs. This will inform how we might improve to help serve your students better.',

  [PartnerProfile.paymentDeadlinesSectionTitle]: 'Payment Deadlines',
  [PartnerProfile.paymentDeadlinesSectionContent]:
    'Please let us know about upcoming payment deadlines and their corresponding minimum required down payments. This will help our operations team prioritize pending applications.',

  [PartnerProfile.imagesSectionTitle]: 'Images',
  [PartnerProfile.imagesSectionContent]:
    'Please upload the corresponding images and keep them up to date. You may click on Image Sample for the proper formats.',
  [PartnerProfile.schoolPhoto]: 'School Photo',
  [PartnerProfile.schoolLogo]: 'School Logo',
  [PartnerProfile.studentID]: 'Student ID',
  [PartnerProfile.schoolBilling]: 'School Billing',
  [PartnerProfile.stockPhotos]: 'Stock Photos',
  [PartnerProfile.imageSample]: 'Image Sample',
  [PartnerProfile.pictureSchool]: 'Add school photo',
  [PartnerProfile.pictureLogo]: 'Add school logo',
  [PartnerProfile.pictureStudentID]: 'Add student ID',
  [PartnerProfile.pictureCertificate]: 'Add school billing',
  [PartnerProfile.pictureClass]: 'Add stock photo',
  [PartnerProfile.fileType]: 'JPEG or PNG',

  [PartnerProfile.populationModalTitle]: 'Population',
  [PartnerProfile.populationModalLabelInput1]: 'Current Population',
  [PartnerProfile.populationModalPlaceholderInput1]: 'E.g. 10000',
  [PartnerProfile.populationModalButtonText]: 'Save',

  [PartnerProfile.shortCoursesModalTitle]: 'Short Courses',
  [PartnerProfile.graduateProgramsModalTitle]: 'Graduate Programs',
  [PartnerProfile.undergraduateProgramsModalTitle]: 'Undergraduate Programs',
  [PartnerProfile.undergraduateProgramsModalLabelInput1]: 'Name of Program',
  [PartnerProfile.undergraduateProgramsModalPlaceholderInput1]:
    'E.g. Computer Science',
  [PartnerProfile.undergraduateProgramsModalLabelInput2]: 'Degree/Batch',
  [PartnerProfile.undergraduateProgramsModalPlaceholderInput2]:
    'E.g. Bachelor of Science',
  [PartnerProfile.undergraduateProgramsModalLabelInput3]: 'Upload batch',
  [PartnerProfile.undergraduateProgramsModalPlaceholderInput3]:
    'Upload your file here',
  [PartnerProfile.undergraduateProgramsModalButtonText]: 'Save Program',

  [PartnerProfile.schoolAddressModalTitle]: 'School Address',
  [PartnerProfile.schoolAddressModalInputProvinceLabel]: 'Current Province',
  [PartnerProfile.schoolAddressModalInputProvincePlaceholder]:
    'Find your current province',
  [PartnerProfile.schoolAddressModalInputCityLabel]: 'Current City',
  [PartnerProfile.schoolAddressModalInputCityPlaceholder]:
    'Find your current city',
  [PartnerProfile.schoolAddressModalInputDistrictLabel]: 'District',
  [PartnerProfile.schoolAddressModalInputDistrictPlaceholder]:
    'Input your district',
  [PartnerProfile.schoolAddressModalInputAddressLine1Label]: 'Address Line 1',
  [PartnerProfile.schoolAddressModalInputAddressLine1Placeholder]:
    'Input address line 1 (e.g. 30E Blue Tower, Asul St.)',
  [PartnerProfile.schoolAddressModalInputAddressLine1Notes]:
    'House no. / Unit no. / Building name, Street name',
  [PartnerProfile.schoolAddressModalInputAddressLine2Label]: 'Address Line 2',
  [PartnerProfile.schoolAddressModalInputAddressLine2Placeholder]:
    'Input address line 2 (e.g. Blue Meadows Subd.)',
  [PartnerProfile.schoolAddressModalInputAddressLine2Notes]:
    'Sitio / Area / Cluster / Subdivision',

  [PartnerProfile.addFacebookURL]: 'Facebook URL or ID',
  [PartnerProfile.addSchoolFacebookURL]: 'Add School’s Facebook URL',

  [PartnerProfile.facebookAccountModalTitle]: '{{prefix}} Facebook Account',
  [PartnerProfile.facebookAccountModalInputLabel]: 'Facebook URL or ID',
  [PartnerProfile.facebookAccountModalInputPrefix]: 'facebook.com/',
  [PartnerProfile.facebookAccountModalInputPlaceholder]: 'Facebook ID',
  [PartnerProfile.facebookAccountModalStep1]:
    '1. Open your Facebook app and login to your Facebook account.',
  [PartnerProfile.facebookAccountModalStep2]:
    '2. From the homepage, click the menu icon on the upper right (looks like three horizontal lines).',
  [PartnerProfile.facebookAccountModalStep3]:
    '3. Click your profile photo or "See your profile" under your name.',
  [PartnerProfile.facebookAccountModalStep4]:
    '4. Click the "Profile Settings" tab (looks like three dots).',
  [PartnerProfile.facebookAccountModalStep5]:
    '5. Scroll down to "Your Profile Link" and click "Copy Link" to copy your complete Facebook profile (should look like this: www.facebook.com/your_fb_id).',

  [PartnerProfile.contactNumberModalTitle]: 'Add Contact Number',
  [PartnerProfile.contactNumberModalInputLabel]: 'Contact number',
  [PartnerProfile.contactNumberModalDropdownOption1]: 'Primary',
  [PartnerProfile.contactNumberModalDropdownOption2]: 'Secondary',

  [PartnerProfile.contactPersonModalTitle]: 'Contact Person',
  [PartnerProfile.contactPersonModalLabelInput1]: 'Name',
  [PartnerProfile.contactPersonModalLabelInput2]: 'Department',
  [PartnerProfile.contactPersonModalLabelInput3]: 'Email',
  [PartnerProfile.contactPersonModalLabelInput4]: 'Mobile Number',
  [PartnerProfile.contactPersonModalPlaceholderInput1]: 'Full Name',
  [PartnerProfile.contactPersonModalPlaceholderInput2]: 'Finance Department',
  [PartnerProfile.contactPersonModalPlaceholderInput3]: 'email@gmail.com',
  [PartnerProfile.contactPersonModalPlaceholderInput4]: 'Mobile',
  [PartnerProfile.contactPersonModalPlaceholderInput5]: '9123123123',
  [PartnerProfile.contactPersonModalButtonText]: 'Save Contact Person',

  [PartnerProfile.paymentDeadlineModalTitle]: 'Payment Deadline',
  [PartnerProfile.paymentDeadlineModalTypeLabel]: 'Type of Payment Deadline',
  [PartnerProfile.paymentDeadlineModalTypePlaceholder]: 'E.g. Midterm Payment',
  [PartnerProfile.paymentDeadlineModalMinRequiredPaymentLabel]:
    'Minimum Required Payment',
  [PartnerProfile.paymentDeadlineModalMinRequiredPaymentPlaceholder]:
    'E.g. P10,000',
  [PartnerProfile.paymentDeadlineModalDateRangeLabel]: 'Select Dates',

  [PartnerProfile.paymentPoliciesModalTitle]: 'Payment Policies',
  [PartnerProfile.paymentPoliciesModalButtonText]: 'Save',

  [PartnerProfile.imageSampleModalTitle]: 'Image Sample',

  [PartnerProfile.tipsForSchoolPhotoTitle]: 'Tips for School Photo',
  [PartnerProfile.tipsForSchoolPhotoContent1]:
    'Should show the building facade of your school',
  [PartnerProfile.tipsForSchoolPhotoContent2]: 'Should have high resolution.',
  [PartnerProfile.tipsForSchoolPhotoContent3]:
    'Should be approved for posting on our website.',

  [PartnerProfile.tipsForSchoolLogoTitle]: 'Tips for School Logo',
  [PartnerProfile.tipsForSchoolLogoContent1]:
    'Should be the most up-to-date copy of your school logo.',
  [PartnerProfile.tipsForSchoolLogoContent2]: 'Should have high resolution.',
  [PartnerProfile.tipsForSchoolLogoContent3]:
    'Should be approved for posting on our website.',

  [PartnerProfile.tipsForStockPhotosTitle]: 'Tips for Stock Photos',
  [PartnerProfile.tipsForStockPhotosContent1]:
    'Should have photos of students who have signed the corresponding release forms allowing the publishing of their photos on publicity materials.',
  [PartnerProfile.tipsForStockPhotosContent2]: 'Should have high resolution.',
  [PartnerProfile.tipsForStockPhotosContent3]:
    'Should be approved for posting on our website.',

  [PartnerProfile.tipsForStudentIdTitle]: 'Tips for Student ID',
  [PartnerProfile.tipsForStudentIdContent1]:
    'Should be a generic copy of your most recent student ID format.',
  [PartnerProfile.tipsForStudentIdContent2]: 'Should have high resolution.',
  [PartnerProfile.tipsForStudentIdContent3]:
    'Should be approved for posting on our borrower application.',

  [PartnerProfile.tipsForSchoolBillingTitle]: 'Tips for School Billing',
  [PartnerProfile.tipsForSchoolBilling1]:
    'Should be a generic copy of your most recent school billing format.',
  [PartnerProfile.tipsForSchoolBilling2]: 'Should have high resolution.',
  [PartnerProfile.tipsForSchoolBilling3]:
    'Should be approved for posting on our borrower application.',

  [PartnerProfile.internalFinancingProgramModalTitle]:
    '{{type}}Internal Financing Program',
  [PartnerProfile.internalFinancingProgramModalNameFieldLabel]: 'Name',
  [PartnerProfile.internalFinancingProgramModalNameFieldPlaceholder]:
    'E.g. 9 Months Installment Plan',
  [PartnerProfile.internalFinancingProgramModalTenorFieldLabel]: 'Tenor',
  [PartnerProfile.internalFinancingProgramModalTenorFieldPlaceholder]:
    'E.g. 3 months',
  [PartnerProfile.internalFinancingProgramModalInterestRateFieldLabel]:
    'Interest Rate',
  [PartnerProfile.internalFinancingProgramModalInterestRateFieldPlaceholder]:
    'E.g. 2%',
  [PartnerProfile.internalFinancingProgramModalOtherFeesFieldLabel]:
    'Other Fees (optional)',
  [PartnerProfile.internalFinancingProgramModalOtherFeesFieldPlaceholder]:
    'E.g. 5% late payment fee',

  [PartnerProfile.removePaymentDeadlineConfirmationModalTitle]:
    'Remove Payment Deadline',
  [PartnerProfile.removePaymentDeadlineConfirmationModalContent]:
    'You are about to remove a payment deadline. Click “Remove” to proceed and “Cancel” to exit.',

  [PartnerProfile.removeInternalFinancingProgramConfirmationModalTitle]:
    'Remove Internal Financing Program',
  [PartnerProfile.removeInternalFinancingProgramConfirmationModalContent]:
    'You are about to remove an internal financing program. Click “Remove” to proceed and “Cancel” to exit.',

  [PartnerProfile.removeProgramConfirmationModalTitle]: 'Remove Program',
  [PartnerProfile.removeProgramConfirmationModalContent]:
    'You are about to remove a program. Click “Remove” to proceed and “Cancel” to exit.',
}

export const METRICS_COPY: CopyRecord<Metrics> = {
  [Metrics.title]: 'Metrics',

  [Metrics.itemMetricsTitle]: 'Metrics',
  [Metrics.itemBorrowersTitle]: 'Total Danacita Users',
  [Metrics.itemCohortsTitle]: 'Cohorts',
  [Metrics.applicationsTabTitle]: 'Applications',
  [Metrics.activationsTabTitle]: 'Activations',
  [Metrics.activationsBarLabel]: '{{value}}\n({{count}} activation(s))',
  [Metrics.activationsBarTooltipValue]: 'Total Value : {{value}}',
  [Metrics.activationsBarTooltipCount]: 'Total Count : {{count}} activation(s)',
  [Metrics.degreeTabTitle]: 'By Degree',
  [Metrics.yearLevelTabTitle]: 'By Year Level',
  [Metrics.borrowersTabTitle]: 'Borrowers',

  [Metrics.schoolFilterTitle]: 'Select School/s',
  [Metrics.monthFilterTitle]: 'Select Month/s',
  [Metrics.schoolFilterPlaceholder]: 'Select Schools',
  [Metrics.mothFilterPlaceholderStart]: 'Start',
  [Metrics.mothFilterPlaceholderEnd]: 'End',

  [Metrics.applicationsEmptyTitle]: 'No data available just yet!',
  [Metrics.activationsEmptyTitle]: 'No data available just yet!',
  [Metrics.borrowersEmptyTitle]: `We don't have active user from your school yet`,
  [Metrics.cohortsDegreeEmptyTitle]: 'You dont have cohorts by degree data yet',
  [Metrics.cohortsYearEmptyTitle]: `You don't have cohorts by year level yet`,

  [Metrics.applicationsEmptyDescription]: 'Check back with us soon.',
  [Metrics.activationsEmptyDescription]: 'Check back with us soon.',
  [Metrics.borrowersEmptyDescription]:
    'Total user from your school will be shown here',
  [Metrics.cohortsDegreeEmptyDescription]:
    'Total cohorts by degree will be shown here',
  [Metrics.cohortsYearEmptyDescription]:
    'Total cohorts by year will be shown here',
}

export const INTEGRATIONS_COPY: CopyRecord<Integrations> = {
  [Integrations.title]: 'API page',

  [Integrations.documentationTitleText]: `${APP_NAME}Pay APIs`,
  [Integrations.documentationInfoText]:
    'Receive expedited applications and faster disbursements with our user-friendly APIs and UI. ',
  [Integrations.keyText]: 'Keys',
  [Integrations.testKeyText]: 'Test',
  [Integrations.liveKeyText]: 'Live',

  [Integrations.keySecurityTextTitle]: 'Key Security ⚠️',
  [Integrations.keySecurityTextInfo]:
    'Treat your API keys like passwords. Anyone who has your REST API keys can send notifications from your platform. Do not expose the key on your application code. Do not share on GitHub or anywhere online.',

  [Integrations.redirectText]: 'Redirect',
  [Integrations.placeholderRedirectURLText]: 'https://sample.url/endpoint',

  [Integrations.redirectFunctionalityTextTitle]: 'Redirect Functionality',
  [Integrations.redirectFunctionalityTextInfo]:
    'Provide a URL that will serve as the redirect for students after they submit their loan application.',
  [Integrations.functionalityTextNote]:
    'This should be useful if you would like to redirect your students back to their school portal.',

  [Integrations.webhookText]: 'Webhooks',
  [Integrations.placeholderWebhookURLText]: 'https://sample.url/endpoint',
  [Integrations.contractSignedWebhookTextTitle]: 'Contract Signed',
  [Integrations.contractSignedWebhookTextInfo]:
    'A notification will be sent to this webhook when the student signs their contract.',
  [Integrations.disbursementWebhookTextTitle]: 'Disbursed',
  [Integrations.disbursementWebhookTextInfo]:
    "A notification will be sent to this webhook when a student's loan is disbursed to the school.",
  [Integrations.cancellationWebhookTextTitle]: 'Cancelled',
  [Integrations.cancellationWebhookTextInfo]:
    "A notification will be sent to this webhook when a student's loan is cancelled.",
  [Integrations.allStatusWebhookTextTitle]: 'All Statuses',
  [Integrations.allStatusWebhookTextInfo]:
    'Live notifications will be sent to this webhook to provide updates on all the status changes of the loans linked to your school/s.',

  [Integrations.downloadKeyModalTitle]:
    'You are about to download  a new {{name}} key!',
  [Integrations.downloadKeyModalContent]:
    'Downloading a new key will revoke your old key and will cause regressions in your implementation. Make sure to adjust your code accordingly.',

  [Integrations.keyHighlightText]:
    'Click download button to generate your <0>{{apiKey}}</0>',
  [Integrations.apiKey]: 'API Key',
}

export const ADMISSIONS_COPY: CopyRecord<Admissions> = {
  [Admissions.title]: 'Admissions',
  [Admissions.subheading]: 'Welcome to your Bukas Dashboard',

  [Admissions.filter]: 'Filter',
  [Admissions.allDays]: 'All',
  [Admissions.day]: 'Day',
  [Admissions.days]: 'Days',
  [Admissions.applyFilter]: 'Apply Filters',
  [Admissions.filterBy]: 'Filter By',
  [Admissions.reset]: 'Reset',
  [Admissions.filterDateApplied]: 'Date Applied',
  [Admissions.filterEnrollmentType]: 'Enrollment Type',
  [Admissions.filterProgramStudy]: 'Program',
  [Admissions.filterPartnerName]: 'School',

  [Admissions.headingDateApplied]: 'Date Applied',
  [Admissions.headingEnrollmentType]: 'Enrollment Type',
  [Admissions.headingName]: 'Name (Last, First)',
  [Admissions.headingMobilePhone]: 'Mobile Number',
  [Admissions.headingEmail]: 'Email',
  [Admissions.headingSchool]: 'School',
  [Admissions.headingProgramStudy]: 'Program',
  [Admissions.headingAction]: 'Action',

  [Admissions.overviewHeader]: 'Sample Student',
  [Admissions.overviewEmail]: 'Email',
  [Admissions.overviewMobileNumber]: 'Mobile Number',
  [Admissions.overviewDateOfBirth]: 'Date of Birth',
  [Admissions.overviewEnrollmentType]: 'Enrollment Type',
  [Admissions.overviewSchool]: 'School',
  [Admissions.overviewPreviousSchool]: 'Current / Previous School',
  [Admissions.overviewDatetimeApplied]: 'Date Applied',
  [Admissions.overviewShsStrand]: 'SHS Strand',
  [Admissions.overviewProgramStudy]: 'Preferred Degree Program/s',
}

export const PERMISSIONS_COPY: CopyRecord<Permissions> = {
  [Permissions.title]: 'Users & Permissions',

  [Permissions.titleStep1]: 'Set up your roster',
  [Permissions.descStep1]: 'Add up to 5 users to your partner dashboard.',

  [Permissions.titleStep2]: 'Specify their access',
  [Permissions.descStep2]:
    'Dictate what each user can and cannot access on your partner dashboard.',

  [Permissions.titleStep3]: 'Assign to multiple schools',
  [Permissions.descStep3]:
    'Specify which schools each admin user has access to.',

  [Permissions.titleBlankState]: 'Add your new admin',
  [Permissions.descBlankState]: `You can add up to ${REACT_APP_MAXIMUM_PARTNER_USER} admin users per campus.`,

  [Permissions.tabAdministrators]: 'Administrators',
  [Permissions.tabRoles]: 'Roles',

  [Permissions.administratorsHeading]: 'Admin List',
  [Permissions.rolesHeading]: 'Admin Role',

  [Permissions.accessText]: 'Access :',
  [Permissions.viewIcon]: 'View',
  [Permissions.downloadIcon]: 'Download',
  [Permissions.editIcon]: 'Edit',
  [Permissions.deleteIcon]: 'Delete',

  [Permissions.headingName]: 'Name',
  [Permissions.headingEmail]: 'Email',
  [Permissions.headingSchools]: 'Schools',
  [Permissions.headingRole]: 'Role',
  [Permissions.headingAction]: 'Action',
  [Permissions.headingStudentData]: 'Student Data',
  [Permissions.allCampusStudentData]: 'All Campus',
  [Permissions.selectedOnlyCampusStudentData]: 'Selected Campus Only',

  [Permissions.addUserSuccessTitle]: 'Added new admin successfully!',
  [Permissions.addUserSuccessDesc]: `We have sent an email invitation to the new admin with instructions on how to finish setting up their account.`,

  [Permissions.editUserSuccessTitle]: 'Updated admin successfully!',
  [Permissions.editUserSuccessDesc]: `We have sent an email notification to the updated admin`,

  [Permissions.addNewAdminModalHeading]: 'Add New Admin',
  [Permissions.addNewAdminModalFirstNameFieldLabel]: 'First name',
  [Permissions.addNewAdminModalLastNameFieldLabel]: 'Last name',
  [Permissions.addNewAdminModalEmailFieldLabel]: 'Email',
  [Permissions.addNewAdminModalRoleFieldLabel]: 'Role',
  [Permissions.addNewAdminModalCampusFieldLabel]: 'Campus',

  [Permissions.editAdminModalHeading]: 'Edit Admin',
  [Permissions.editAdminModalFirstNameFieldLabel]: 'First name',
  [Permissions.editAdminModalLastNameFieldLabel]: 'Last name',
  [Permissions.editAdminModalEmailFieldLabel]: 'Email',
  [Permissions.editAdminModalRoleFieldLabel]: 'Role',
  [Permissions.editAdminModalCampusFieldLabel]: 'Campus',

  [Permissions.deleteAdminModalHeading]: 'Remove Admin',
  [Permissions.deleteAdminModalContent]: 'Are you sure to delete this account?',
}

export const DIGEST_COPY: CopyRecord<Digest> = {
  [Digest.title]: 'Digest Validator Page',
  [Digest.documentationTitleText]: 'Digest Validator',
  [Digest.documentationInfoText]:
    'Input your data into the Digest Validator to get the expected string and digest you would need to access our endpoints.',
  [Digest.integrationsType]: 'Integration Type',
  [Digest.action]: 'Generate Digest',
  [Digest.requiredField]: 'Required Fields for Digest',
  [Digest.copyClipboad]: 'Copy to clipboard',
  [Digest.addressLine1]: 'Address Line 1',
  [Digest.addressLine2]: 'Address Line 2',
  [Digest.balanceFromOlderTerms]: 'Balance from Older Terms',
  [Digest.city]: 'City',
  [Digest.currentDueTotal]: 'Current Due Total',
  [Digest.dateOfBirth]: 'Date of Birth',
  [Digest.degree]: 'Degree',
  [Digest.email]: 'Email Address',
  [Digest.firstName]: 'First Name',
  [Digest.guardianFirstName]: 'Guardian First Name',
  [Digest.guardianLastName]: 'Guardian Last Name',
  [Digest.guardianMobileNumber]: 'Guardian Mobile Number',
  [Digest.lastName]: 'Last Name',
  [Digest.maritalStatus]: 'Marital Status',
  [Digest.maxDaysInArrears]: 'Max Days in Arrears',
  [Digest.mobileNumber]: 'Mobile Number',
  [Digest.outstandingBalanceFromPreviousTerm]:
    'Outstanding Balance from Previous Term',
  [Digest.placeOfBirth]: 'Place of Birth',
  [Digest.program]: 'Program',
  [Digest.province]: 'Province',
  [Digest.school]: 'School',
  [Digest.studentIdNumber]: 'Student ID Number',
  [Digest.studentStatus]: 'Student Status',
  [Digest.subDistrict]: 'Sub District',
  [Digest.totalDueForUpcomingTerm]: 'Total Due for Upcoming Term',
  [Digest.totalDueFromPreviousTerm]: 'Total Due from Previous Term',
  [Digest.course]: 'Program',
  [Digest.dateDue]: 'Date Due',
  [Digest.daysInArrears]: 'Days Past Due',
  [Digest.guardianEmail]: 'Guardian Email',
  [Digest.schoolRemainingBalance]: 'School Remaining Balance',
  [Digest.year]: 'Year',
}

export const LITE_COPY: CopyRecord<Lite> = {
  [Lite.product]: 'DanacitaPay Lite+',
  [Lite.productDesc]:
    'Provide an additional check-out option on your school portal with an easy-to-install widget and make Danacita accessible to your students.',
  [Lite.subheading]:
    'Welcome! Here you can find all the tools you need to get you set up with Danacita.',
  [Lite.bannerDesc]:
    'This DanacitaPay Banner is perfect if you want a horizontal widget that fits your interface/page/portal while being able to showcase Danacita as a payment option for your school.',
  [Lite.instalation]: 'Installation',
  [Lite.instalationStep1]:
    '1. Copy and paste the snippet of code into the HTML file of your payment page/s.',
  [Lite.instalationStep2]:
    '2. Sit back and see our script run on your test/live portal.',
  [Lite.buttonDesc]:
    'This DanacitaPay Button is perfect if you want a compact-looking widget that could still easily showcase Danacita as a payment option for your school.',
  [Lite.landingDesc]:
    'This DanacitaPay Button is perfect if you want a compact-looking widget that could still easily showcase Danacita as a payment option for your school.',
  [Lite.note]:
    'Note: Provided snippets are customized per school. Kindly ensure you are using the correct snippet for the school you are developing for.',
  [Lite.copy]: 'Copy Snippet',
  [Lite.selectSchool]: 'Select School',
  [Lite.selectInterface]: 'Select Interface',
  [Lite.selectSize]: 'Select Size',
  [Lite.blankTitle]:
    'Your selected school/campus is currently not a Lite+ partner',
  [Lite.blankSubtitle]:
    'If you are interested in setting up BukasPay as a checkout option, kindly reach out to your Bukas Account Manager.',
}
